import { Box, List, ListItem, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import JiraProjectInfo from '../JiraProjectInfo';
import { useFetchJiraIssueTypes, useFetchJiraIssueMetaData } from '@http/queries';
import { useState, forwardRef, useImperativeHandle, useEffect, useMemo, useCallback } from 'react';
import useJiraExport from '@hooks/useJiraExport';
import LoadingSpinner from '@/components/LoadingSpinner';
import JiraUserInfo from '../JiraUserInfo';
import { Assignee, DueDate, IssueType, Priority, ProjectComponents } from './IssueFields';
import addWeeks from 'date-fns/addWeeks';
import InputHelperText from '@/components/InputHelperText';
import { useAtom } from 'jotai';
import { todosDataAtom } from '@/global-store';

const defaultFields = [
    'reporter',
    'project',
    'summary',
    'issuetype',
    'description',
    'duedate',
    'priority'
];
const supportedFields = [...defaultFields, 'components', 'assignee'];

const IssueExportForm = forwardRef(function IssueExportFormRef(
    { todosToExport, jiraProject, linkedJiraUserID, jiraUsers },
    ref
) {
    const { t } = useTranslation();
    const [errorResponse, setErrorResponse] = useState(false);
    const [notSupportedFields, setNotSupportedFields] = useState(null);
    const [invalidFields, setInvalidFields] = useState(null);
    const [todosData] = useAtom(todosDataAtom);
    const [issueFields, setIssueFields] = useState(() => {
        const tomorrow = addWeeks(new Date(), 2);
        let assigneeUser = linkedJiraUserID;
        if (todosToExport?.length === 1 && todosToExport[0].assignee)
            assigneeUser =
                todosData?.jira?.jiraUsersMap[todosToExport[0].assignee] ?? linkedJiraUserID;
        return {
            project: jiraProject?.key,
            reporter: linkedJiraUserID ?? null,
            assignee: assigneeUser,
            issuetype: 0,
            duedate: tomorrow,
            priority: '3'
        };
    });

    const { data: jiraIssueTypes } = useFetchJiraIssueTypes(jiraProject?.key);
    const { data: jiraIssueMetaData } = useFetchJiraIssueMetaData(
        jiraProject?.key,
        issueFields.issuetype
    );

    const handleValuesChange = useCallback((field, value) => {
        setIssueFields(prevVal => {
            const updatedData = { ...prevVal };
            updatedData[field] = value;
            return updatedData;
        });
    }, []);

    const { handlePostToJira, handlePostMulToJira } = useJiraExport();

    useEffect(() => {
        if (jiraIssueMetaData) {
            setIssueFields(prevVal => {
                const updatedData = { ...prevVal };
                Object.keys(jiraIssueMetaData)
                    ?.filter(
                        fieldKey =>
                            jiraIssueMetaData[fieldKey].required &&
                            !defaultFields.includes(fieldKey)
                    )
                    .forEach(fieldKey => {
                        updatedData[fieldKey] =
                            jiraIssueMetaData[fieldKey].schema?.type === 'array' ? [] : null;
                    });
                return updatedData;
            });
            const _notSupportedFields = Object.keys(jiraIssueMetaData)?.filter(
                fieldKey =>
                    jiraIssueMetaData[fieldKey].required && !supportedFields.includes(fieldKey)
            );
            setNotSupportedFields(_notSupportedFields);
        }
    }, [jiraIssueMetaData]);

    const jiraLinkedUser = useMemo(() => {
        if (!linkedJiraUserID || !jiraUsers) return undefined;

        return jiraUsers?.find(jiraUser => jiraUser.accountId == linkedJiraUserID);
    }, [linkedJiraUserID, jiraUsers]);

    const todoAssigneeUser = useMemo(() => {
        if (!todosToExport?.length || todosToExport?.length > 1 || !jiraUsers) return undefined;
        const assigneeAccountId = todosData?.jira?.jiraUsersMap[todosToExport[0].assignee];

        return jiraUsers?.find(jiraUser => jiraUser.accountId == assigneeAccountId);
    }, [todosData?.jira, todosToExport, jiraUsers]);

    useEffect(() => {
        if (jiraIssueTypes && jiraIssueTypes.length > 0) {
            setIssueFields(prevVal => {
                const updatedData = { ...prevVal };
                updatedData.issuetype = jiraIssueTypes[0]?.id;
                return updatedData;
            });
        }
    }, [jiraIssueTypes]);

    const scrollToSection = id => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' }); // Scrolls smoothly to the element
        }
    };

    const handlePost = async () => {
        // dont proceed if non supported fields exists
        if (notSupportedFields.length) {
            scrollToSection('jiraExportErrorRow');
            return false;
        }

        // dont proceed if there are non valid entries
        const isValid = validateForm();
        if (!isValid) {
            scrollToSection('jiraExportErrorRow');
            return false;
        }

        let response;
        if (todosToExport.length > 1) {
            const jiraIssueConfig = {
                todos: todosToExport,
                projectKey: jiraProject?.key,
                ...issueFields
            };
            response = await handlePostMulToJira(jiraIssueConfig);
            setErrorResponse(todosToExport.length === response.length);
        } else {
            const jiraIssueConfig = {
                todo: todosToExport[0],
                projectKey: jiraProject?.key,
                ...issueFields
            };
            response = await handlePostToJira(jiraIssueConfig);
            setErrorResponse(!response);
        }
        console.log('jiraResponse', 'handlePost', response);
        return response;
    };

    useImperativeHandle(ref, () => ({
        handlePost
    }));

    const validateForm = () => {
        const inValidFieldsList = Object.keys(jiraIssueMetaData)?.filter(fieldKey => {
            if (!jiraIssueMetaData[fieldKey].required || defaultFields.includes(fieldKey))
                return false;

            let filter = false;
            if (Array.isArray(issueFields[fieldKey])) filter = issueFields[fieldKey].length === 0;
            else filter = !issueFields[fieldKey];
            return filter;
        });
        setInvalidFields(inValidFieldsList);
        return !inValidFieldsList.length;
    };

    if (!jiraIssueMetaData || !jiraIssueTypes || !jiraUsers) return <LoadingSpinner />;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <InfoRow title='Project'>
                <JiraProjectInfo project={jiraProject} />
            </InfoRow>

            <InfoRow title={t('issueType')}>
                <IssueType
                    issueType={issueFields.issuetype}
                    jiraIssueTypes={jiraIssueTypes}
                    handleChange={handleValuesChange}
                    projectKey={jiraProject?.key}
                />
            </InfoRow>

            <InfoRow title={t('assignee')}>
                <Assignee
                    assignee={issueFields.assignee}
                    jiraUsers={jiraUsers}
                    handleChange={handleValuesChange}
                    todoAssigneeUser={todoAssigneeUser}
                />
            </InfoRow>

            {jiraLinkedUser ? (
                <InfoRow title={t('reporter')}>
                    <JiraUserInfo user={jiraLinkedUser} hideActiveInfo />
                </InfoRow>
            ) : null}

            {Object.prototype.hasOwnProperty.call(issueFields, 'components') ? (
                <InfoRow title='Components' hasErrors={invalidFields?.includes('components')}>
                    <Box sx={{ width: '100%', maxWidth: '300px' }}>
                        <ProjectComponents
                            components={issueFields.components}
                            allowedValues={jiraIssueMetaData?.components?.allowedValues}
                            handleChange={handleValuesChange}
                            projectKey={jiraProject?.key}
                        />
                    </Box>
                </InfoRow>
            ) : null}

            <InfoRow title={t('duedate')}>
                <Box sx={{ width: '100%', maxWidth: '300px' }}>
                    <DueDate value={issueFields?.duedate} handleChange={handleValuesChange} />
                </Box>
            </InfoRow>

            {jiraIssueMetaData?.priority?.allowedValues ? (
                <InfoRow title={t('priority')}>
                    <Box sx={{ width: '100%', maxWidth: '300px' }}>
                        <Priority
                            value={issueFields.priority}
                            defaultValue={jiraIssueMetaData?.priority?.defaultValue}
                            allowedValues={jiraIssueMetaData?.priority?.allowedValues}
                            handleChange={handleValuesChange}
                        />
                    </Box>
                </InfoRow>
            ) : null}

            {errorResponse ? <ErrorRow>{t('issueJiraExportFailedMsg')}</ErrorRow> : null}

            {notSupportedFields?.length ? (
                <ErrorRow>
                    <div dangerouslySetInnerHTML={{ __html: t('fieldsNotSupportedError') }}></div>
                    <List>
                        {notSupportedFields?.map(field => (
                            <ListItem key={field} dense>
                                <ListItemText
                                    primary={jiraIssueMetaData[field].name}
                                    secondary={field}
                                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                                />
                            </ListItem>
                        ))}
                    </List>
                </ErrorRow>
            ) : null}
        </Box>
    );
});

function InfoRow({ title, children, hasErrors = false, required }) {
    const { t } = useTranslation();
    title += required ? '*' : '';
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: { xs: 'stretch', md: 'flex-start' },
                gap: 2,
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: 'space-between'
            }}
        >
            <Box
                sx={[
                    hasErrors
                        ? {
                              color: '#a82424'
                          }
                        : {
                              color: '#000'
                          }
                ]}
            >
                <span>{title}:</span>
                {hasErrors ? <InputHelperText text={t('required')} sx={{ margin: 0 }} /> : null}
            </Box>
            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'end' }}>{children}</Box>
        </Box>
    );
}

function ErrorRow({ children }) {
    return (
        <Box
            id='jiraExportErrorRow'
            sx={{
                color: '#531514',
                backgroundColor: '#faeaea',
                width: '100%',
                maxWidth: '450px',
                textAlign: 'left',
                p: 1,
                borderRadius: '5px'
            }}
        >
            {children}
        </Box>
    );
}

export default IssueExportForm;
