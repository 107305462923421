import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ColoredDot from '@pages/report/Lighthouse/components/ColoredDot.jsx';
import PropTypes from 'prop-types';
import { useState } from 'react';

/**
 * Component to display tabs with panels that focus on accessibility.
 * @param props {Object}
 * @param props.tabs{TabItem[]}
 * @returns {Element}
 */
function TabsWithPanels({ tabTextColor = '#000', tabs = [], selectedTab = 0 }) {
    const [activeTab, setActiveTab] = useState(selectedTab);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const variant = isSmallScreen ? 'scrollable' : 'fullWidth';

    return (
        <TabContext value={activeTab}>
            <Box sx={{ borderBottom: 1.5, borderColor: '#333' }}>
                <TabList
                    scrollButtons
                    allowScrollButtonsMobile
                    selectionFollowsFocus
                    onChange={(e, val) => setActiveTab(val)}
                    aria-label='Subnavigation Tabs'
                    variant={variant}
                >
                    {tabs.map((tab, idx) => {
                        const { content, showDot, title, ...rest } = tab; // eslint-disable-line
                        const label = showDot ? (
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    color: 'inherit'
                                }}
                            >
                                <Box
                                    sx={{
                                        fontWeight: 600,
                                        color: 'inherit'
                                    }}
                                >
                                    {title}
                                </Box>
                                <Box
                                    sx={{
                                        width: '25px'
                                    }}
                                >
                                    <ColoredDot color='#e80000' />
                                </Box>
                            </Box>
                        ) : (
                            title
                        );
                        return (
                            <Tab
                                {...rest}
                                disableRipple
                                disableFocusRipple
                                sx={{
                                    'color': tabTextColor,
                                    ':focus, :hover': {
                                        outline: `3px solid ${tabTextColor} !important`
                                        //outlineOffset: '-1px'
                                    },
                                    ...(rest.sx || {})
                                }}
                                key={tab.title + idx}
                                label={label}
                                value={idx}
                            />
                        );
                    })}
                </TabList>
            </Box>
            {tabs.map((tab, idx) => {
                return (
                    <TabPanel value={idx} key={tab.title}>
                        {tab.content}
                    </TabPanel>
                );
            })}
        </TabContext>
    );
}

TabsWithPanels.propTypes = {
    tabTextColor: PropTypes.string,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            content: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
            onClick: PropTypes.func,
            showDot: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
        })
    ).isRequired
};

export default TabsWithPanels;
