import { demoModeAtom } from '@/global-store';
import RouterLink from '@components/RouterLink';
import { Box, Button, Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

export function ProductCard({ product, link, icon, learnMoreLink }) {
    const { t } = useTranslation();
    const [demoMode] = useAtom(demoModeAtom);

    return (
        <Paper
            elevation={1}
            sx={{
                'borderRadius': 3,
                'border': '2px solid #666',
                'height': '100%',
                'position': 'relative',
                'zIndex': 0,
                'overflow': 'hidden',
                'transition': 'all 0.18s linear',

                '&:hover': {
                    'boxShadow': '0px 4px 8px rgba(38, 38, 38, 0.2)',
                    'color': '#fff',
                    'transform': 'scale(1.03)',

                    '& h2': {
                        color: '#fff'
                    },

                    '& .MuiButton-root': {
                        'backgroundColor': '#FFD050',
                        'color': '#252525',

                        '&:hover': {
                            backgroundColor: '#252525',
                            borderColor: '#FFD050',
                            color: '#FFD050'
                        }
                    },

                    '& .icon-background:before': {
                        backgroundColor: '#252525',
                        transform: 'scale(19)',
                        transformOrigin: '50% 50%'
                    },

                    '& .tabler-icon': {
                        stroke: '#FFD050 !important'
                    },

                    '& a[target="_blank"]': {
                        color: '#FFD050'
                    }
                }
            }}
        >
            <Grid
                container
                direction='column'
                sx={{
                    gap: 2.75,
                    justifyContent: 'space-between',
                    p: 2.5,
                    height: '100%'
                }}
            >
                <Grid
                    container
                    sx={{
                        alignItems: 'center',
                        flexWrap: 'nowrap',
                        gap: 1.5,
                        justifyContent: 'space-between'
                    }}
                >
                    <Typography
                        component='h2'
                        sx={{
                            transition: 'all 0.3s linear !important'
                        }}
                        variant='h4'
                    >
                        Eye-Able® {product}
                    </Typography>

                    <Box
                        className='icon-background'
                        sx={{
                            'flexShrink': 0,
                            'zIndex': -1,
                            'position': 'relative',
                            'display': 'inline-flex',
                            'alignItems': 'center',
                            'justifyContent': 'center',
                            'width': 54,
                            'height': 54,
                            '&::before': {
                                transition: 'all .5s linear',
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                borderRadius: '50%', // to create a circular shape
                                backgroundColor: '#252525'
                            },
                            '& .tabler-icon': {
                                transition: 'all .4s linear',
                                stroke: '#fff !important',
                                zIndex: 1
                            }
                        }}
                    >
                        {icon}
                    </Box>
                </Grid>

                <Typography
                    component='div'
                    sx={{
                        transition: 'all 0.3s linear !important'
                    }}
                    variant='body1'
                >
                    {t('demoCardText+' + product)}
                </Typography>

                <RouterLink
                    sx={{
                        transition: 'color .4s linear'
                    }}
                    to={learnMoreLink}
                    underline='always'
                >
                    {t('learnMoreAbout')} Eye-Able®
                    {product}
                </RouterLink>

                <Button
                    color='primary'
                    fullWidth
                    href={link}
                    sx={{
                        fontSize: { xs: '12px', sm: '13px', lg: '14px' },
                        alignSelf: 'flex-end',
                        transition: 'all .2s linear'
                    }}
                    variant='outlined'
                >
                    {t(demoMode ? 'tryDemo' : 'useNow', { product: 'Eye-Able ' + product })}
                </Button>
            </Grid>
        </Paper>
    );
}
